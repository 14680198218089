import DOMPurify from 'dompurify';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

function DropNavLink({ path, title, svg }) {
    const { pathname } = useLocation();

    return (
        <li>
            <Link
                to={path}
                className={`${pathname.split("/")[1] === path ? "bg-primary text-white" : ""
                    } flex items-center px-6 py-3 mx-2 rounded text-gray-700  dark:hover:bg-gray-800 hover:text-white hover:bg-primary transition-all`}
            >
                <span
                    className="mr-2 "
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(svg) }}
                />
                <span> {title} </span>
            </Link>
        </li>
    )
}

export default DropNavLink