import React from "react";

const AdminHome = () => {


  return (
    <>
      <div className="text-xl font-bold text-center">Welcome to Questionnaire</div>

    </>
  );
};

export default AdminHome;
