// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
// import { Navigate, useLocation, useParams } from "react-router-dom";

// import routeValue from "../../utilities/routeValue.json";

const Protected = ({ isLoggedIn, children }) => {
  // const { profile } = useSelector((state) => state.user);

  // const location = useLocation();
  // const params = useParams();
  // const param = Object.values(params)[0];

  // const path = location.pathname.replace(`/${param}`, "");

  // const roles = [
  //   "Get All User",
  //   "Get All Farmer",
  //   "Get All Product",
  //   "Get All Post",
  //   "Get All UserLog",
  //   "Get All Role",
  //   "Get All Setting",
  //   "Get All TagType",
  // ];

  // useEffect(() => {
  //   // eslint-disable-next-line
  // }, [profile]);

  // if (isLoggedIn) {
  //   console.log(roles.includes(routeValue[path]));
  // }

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};
export default Protected;
