import { setPostCounts } from "../features/post/postSlice";
import { call, put } from "redux-saga/effects";
import { get } from "../../httpServices";

export function* getPostCounts({ type, payload }) {
  try {
    const { data } = yield call(get, payload);
    yield put(setPostCounts(data));
  } catch (error) {}
}
