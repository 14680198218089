import React from 'react'

const HomeSkeleton = () => {
    return (
        <>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
            {
                ["a", "b"]?.map((item, index) => (
                    <div key={index} className="p-6 rounded-md shadow dark:bg-gray-900 bg-white">
                        <div className="flex justify-between">
                            <div className="w-full bg-gray-200 animate-pulse h-6 rounded "> </div>
                        </div>
                        <div className="bg-gray-200 animate-pulse w-[60%] h-4 mt-3 rounded"> </div>
                        <div className="flex flex-wrap gap-2 mt-4 mb-4">
                            <div className="bg-gray-200 animate-pulse w-24 h-8 rounded"></div>
                            <div className="bg-gray-200 animate-pulse w-24 h-8 rounded"></div>
                        </div>
                        <div className='flex flex-wrap gap-3'>
                            <div className='w-[30%] h-10 bg-blue-100 animate-pulse'> </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </>
    )
}

export default HomeSkeleton