import React from "react";
const CustomImage = ({ name, source, ...props }) => {
  const imagePlaceholder = require(`../../assets/imgplaceholder.png`);
  if (name) {
    const image = require(`../../assets/${name}`);
    return (
      <img
        src={image}
        {...props}
        alt=""
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = imagePlaceholder;
        }}
      />
    );
  }
  if (!!source) {
    return (
      <img
        src={`${process.env.REACT_APP_IMAGE_BASE_URL}${source}`}
        alt=""
        {...props}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = imagePlaceholder;
        }}
      />
    );
  } else {
    return <img src={imagePlaceholder} {...props} alt="" />;
  }
};

export default CustomImage;
