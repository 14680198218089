import React from 'react'
const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const Footer = () => {
    return (
        <div className="px-4 py-6 bg-white dark:bg-gray-800  shadow">
            <div className="flex flex-wrap items-center justify-center">
                <div className=""> © Copyright {currentYear} . All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Footer