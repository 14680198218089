import * as yup from 'yup';

let ChangeProfilePasswordValidation = yup.object().shape({
    OldPassword: yup.string().min(8, 'Your password is too short.').max(15, 'Your password is too long.').required('Required !'),
    Password: yup.string().min(8, 'Your password is too short.').max(15, 'Your password is too long.').required('Required !'),
    ConfirmPassword: yup.string().min(8, 'Your password is too short.').max(15, 'Your password is too long.').oneOf([yup.ref('Password'), null], 'Passwords must match').required('Required !'),


});


export default ChangeProfilePasswordValidation