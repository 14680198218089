import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: null,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCategory: (state, action) => {
      const { payload } = action;

      state.category = payload;
    },
  },

  extraReducers: (builder) => {},
});

export const { setCategory } = categorySlice.actions;

export default categorySlice.reducer;
