import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  GET_USER_PROFILE,
  LOGOUT
} from "../../redux/sagas/actions";
import CustomImage from "../common/CustomImage";

const HeaderNav = ({ openSideNav, setOpenSideNav }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const [showProfileDiv, setshowProfileDiv] = useState(false);

  // const { i18n } = useTranslation();
  // function changeLanguage(e) {
  //   i18n.changeLanguage(e.target.value);
  // }
  useEffect(() => {
    if (profile === null || profile === "undefined") {
      getUserProfile();
      // getCategory();
    }

    // eslint-disable-next-line
  }, []);

  // const getCategory = () => {
  //   const payload = {
  //     url: "category/list",
  //   };
  //   dispatch(GET_CATEGORY(payload));
  // };

  const getUserProfile = () => {
    const payload = {
      url: "user/my-details",
    };
    dispatch(GET_USER_PROFILE(payload));
  };

  const handleLogout = () => {
    const payload = {
      url: "auth/logout",
    };
    dispatch(LOGOUT(payload));
  };

  return (
    <nav className="relative">
      <div className="flex items-center justify-between ml-4">
        <div>
          <button
            onClick={() => setOpenSideNav(!openSideNav)}
            className="px-2 py-3 rounded text-primary bg-primarylight dark:bg-gray-800"
          >
            <svg
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.50002 1.66667H16.5C16.721 1.66667 16.933 1.57887 17.0893 1.42259C17.2456 1.26631 17.3334 1.05435 17.3334 0.833333C17.3334 0.61232 17.2456 0.400358 17.0893 0.244078C16.933 0.0877975 16.721 0 16.5 0H1.50002C1.27901 0 1.06704 0.0877975 0.910765 0.244078C0.754484 0.400358 0.666687 0.61232 0.666687 0.833333C0.666687 1.05435 0.754484 1.26631 0.910765 1.42259C1.06704 1.57887 1.27901 1.66667 1.50002 1.66667V1.66667ZM16.5 8.33333H1.50002C1.27901 8.33333 1.06704 8.42113 0.910765 8.57741C0.754484 8.73369 0.666687 8.94565 0.666687 9.16667C0.666687 9.38768 0.754484 9.59964 0.910765 9.75592C1.06704 9.9122 1.27901 10 1.50002 10H16.5C16.721 10 16.933 9.9122 17.0893 9.75592C17.2456 9.59964 17.3334 9.38768 17.3334 9.16667C17.3334 8.94565 17.2456 8.73369 17.0893 8.57741C16.933 8.42113 16.721 8.33333 16.5 8.33333ZM16.5 4.16667H1.50002C1.27901 4.16667 1.06704 4.25446 0.910765 4.41074C0.754484 4.56702 0.666687 4.77899 0.666687 5C0.666687 5.22101 0.754484 5.43298 0.910765 5.58926C1.06704 5.74554 1.27901 5.83333 1.50002 5.83333H16.5C16.721 5.83333 16.933 5.74554 17.0893 5.58926C17.2456 5.43298 17.3334 5.22101 17.3334 5C17.3334 4.77899 17.2456 4.56702 17.0893 4.41074C16.933 4.25446 16.721 4.16667 16.5 4.16667Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>


        {/* <img src="https://www.helvetas.org/Logos/Helvetas_Logos/HEL_Logo_3d_Nepal_colour_web.png" alt="logo" className="object-contain w-32" /> */}
        <span className="font-bold">Top Nepal</span>
        <div className="flex items-center">


          <div className="relative text-left lg:inline-block">
            <div className="lg:block">
              <button className="flex items-center">
                <div className="hidden mr-3 text-right md:block">
                  <p className="text-sm font-bold text-black ">
                    {" "}
                    {profile?.UserName}{" "}
                    <span className="text-xs font-thin text-gray-400">
                      ({profile?.Role})
                    </span>
                  </p>
                </div>
                <div
                  className="flex items-center"
                  onClick={() => setshowProfileDiv(!showProfileDiv)}
                >
                  <div className="mr-2">
                    <CustomImage
                      alt="person"
                      source={`${!!profile?.ProfileImage ? profile.ProfileImage : ""
                        }`}
                      className="object-cover object-right w-10 h-10 rounded-full"
                    />
                  </div>
                  <span>
                    <svg
                      className="text-gray-400"
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.08335 0.666657C8.75002 0.333323 8.25002 0.333323 7.91669 0.666657L5.00002 3.58332L2.08335 0.666657C1.75002 0.333323 1.25002 0.333323 0.916687 0.666657C0.583354 0.99999 0.583354 1.49999 0.916687 1.83332L4.41669 5.33332C4.58335 5.49999 4.75002 5.58332 5.00002 5.58332C5.25002 5.58332 5.41669 5.49999 5.58335 5.33332L9.08335 1.83332C9.41669 1.49999 9.41669 0.99999 9.08335 0.666657Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </button>
            </div>
            {showProfileDiv && (
              <div
                id="dropdown_profile"
                className="absolute right-0 w-48 mt-3 origin-top-right bg-white rounded shadow dark:bg-gray-800 dark:text-gray-100 "
              >
                <div>
                  <Link
                    to="profile"
                    className="flex px-4 py-2 text-sm text-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100"
                  >
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                    {t('Account')}

                  </Link>

                  <Link
                    to="profile/change-password"
                    className="flex px-4 py-2 text-sm text-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="mr-2 bi bi-key"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                      <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    {t('Change Password')}
                  </Link>

                  <span
                    onClick={handleLogout}
                    className="flex px-4 py-2 text-sm text-gray-700 cursor-pointer dark:hover:bg-gray-800 hover:bg-gray-100"
                  >
                    <svg
                      className="mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    {t('Logout')}


                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderNav;
