import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postCount: null,
  postCountData: null,
};

export const postSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPostCounts: (state, action) => {
      const { payload } = action;
      let { data } = payload;
      state.postCount = {
        Today: data.today.length || 0,
        ThisWeek: data.thisWeek.length || 0,
        ThisMonth: data.thisMonth.length || 0,
        LastMonth: data.lastMonth.length || 0,
      };
      state.postCountData = {
        Today: data.today || [],
        ThisWeek: data.thisWeek || [],
        ThisMonth: data.thisMonth || [],
        LastMonth: data.lastMonth || [],
      };
    },
  },

  extraReducers: (builder) => {},
});

export const { setPostCounts } = postSlice.actions;

export default postSlice.reducer;
