import { ErrorMessage, Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { TextError } from "../TextError";

const FormikCheckBox = ({ name, options, label }) => {
  const { t } = useTranslation();
  return (
    <div>
      <label className="block mb-2 text-sm font-medium ">{t(label)}</label>
      <div className="flex items-center">
        {options?.map((val) => {
          return (
            <div key={val.value} className="flex mr-5 items-center">
              <Field
                type="checkbox"
                className="cursor-pointer"
                name={name}
                value={val.value}
              />
              <label className="ml-2 text-sm">{t(val.label)}</label>
            </div>
          );
        })}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikCheckBox;
