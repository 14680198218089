import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";

const RolesList = () => {
  const navigate = useNavigate();
  const tableHeading = [
    "Title",
    "UniqueName",
    "PermissionValue",
    "Status",
    "Action",
  ];
  const [roleList, setRoleList] = useState([]);
  // eslint-disable-next-line
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = LIMIT;
  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfRoles();
      }, 1500);
    } else {
      getListOfRoles();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage]);

  const getListOfRoles = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "role/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    });

    if (success) {
      setIsLoading(false);
      setRoleList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const handleDeleteRole = async () => {
    const { message, success } = await new APIServices(
      `role/${selectedRoleId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfRoles();
    }
  };

  const handleEditRole = (id) => {
    navigate(`/setting/role/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/setting/role/view/${id}`);
  };

  const navigateToAddNewRole = () => {
    navigate("/setting/role/add");
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry
                title="List of Roles"
                navigate={navigateToAddNewRole}
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={roleList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                handleEdit={handleEditRole}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
      )}
    </section>
  );
};

export default RolesList;
