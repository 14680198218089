import React, { useState, useEffect } from "react";
import APIServices from "../../../httpServices/httpServices";
import { useNavigate, useParams } from "react-router-dom";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import DeleteModal from "../../common/modals/DeleteModal";

const ViewQuestion = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id) {
            getDetailView();
        }
        // eslint-disable-next-line
    }, [id]);

    // Fetch User Data
    const getDetailView = async () => {
        const { data, success } = await new APIServices(`question/${id}`).get();
        if (success) {
            setData(data);
        }
    };

    const handleShowDeleteModal = (id) => {
        setShowModal(true);
    };

    const handleDeleteRole = async () => {
        const { message, success } = await new APIServices(`question/${id}`).delete();
        if (success) {
            setShowModal(false);
            emitSuccessToast(message);
            navigate("/question");
        }
    };

    const { _id, Title, Slug, Detail, Type, OrderBy, Hint, IsRequired, Validation, Tag, Label, Point, Status } = data;

    return (
        <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
            <div className="flex justify-between mt-5 mb-4 items-end">
                <h2 className="font-bold text-lg"> Question  Information </h2>
                {Status === "Inactive" && (
                    <button
                        onClick={() => handleShowDeleteModal(_id)}
                        className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
                    >
                        Delete
                    </button>
                )}
            </div>
            <div className="shadow bg-white border-gray-500 p-8 rounded">
                <div className="flex flex-wrap">
                    <DataView label="Title" value={Title} />
                    <DataView label="Slug" value={Slug} />
                    <DataView label="Status" value={Status} />
                    <DataView label="Detail" value={Detail} />
                    <DataView label="Type" value={Type} />
                    <DataView label="OrderBy" value={OrderBy} />
                    <DataView label="Hint" value={Hint} />
                    <DataView label="IsRequired" value={IsRequired} />
                    <DataView label="Validation" value={Validation} />
                    <DataView label="Point" value={Point} />
                    <DataView label="Label" value={Label} />
                    <DataView label="Tag" value={Tag} />
                </div>
            </div>
            {showModal && (
                <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
            )}
        </div>
    );
};

export default ViewQuestion;
