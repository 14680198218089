import React, { useState } from "react";
import { Link } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ForgotPassword = () => {
  const [errMsg, setErrMsg] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [formData, setformData] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    if (errMsg) {
      setErrMsg("");
    }
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.email) {
      if (!formData.email.match(validRegex)) {
        setErrMsg("Enter a valid email address");
        return;
      }
      const payload = {
        Email: formData.email,
      };
      const { success, message } = await new APIServices(
        "/auth/forgot-password"
      ).post(payload);
      if (success) {
        setIsEmailSent(true);
      } else {
        setErrMsg(message);
      }
    } else {
      setErrMsg("Required !");
    }
  };

  return (
    <section className="bg-gray-100">
      <div className="max-w-md mx-auto">
        {!isEmailSent ? (
          <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-10 rounded shadow">
              <div className="mb-4 text-center">
                <h3 className="text-2xl font-bold mb-3">
                  {" "}
                  Forgot Your Password{" "}
                </h3>
                <p className="mb-5 text-sm text-gray-700">
                  We get it, stuff happens. Just enter your email address below
                  and we'll send you a link to reset your password!
                </p>
              </div>
              <form onSubmit={handleSubmit} className="bg-white rounded">
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-medium "
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData?.email}
                    onChange={handleInputChange}
                    placeholder="Enter Email Address..."
                    className={`w-full px-4 py-3 bg-white text-sm leading-tight text-gray-700 border rounded  dark:text-gray-100 dark:bg-gray-800 dark:border dark:border-gray-800 ${
                      errMsg && "border-red-600"
                    }`}
                  />
                  {errMsg && (
                    <div className="text-red-600 mt-2 ml-1">{errMsg}</div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Send
                  </button>
                </div>
                <div className="mt-2 text-right">
                  <Link
                    to="/auth/login"
                    className="text-sm hover:text-blue-700 text-gray-500 cursor-pointer"
                  >
                    {" "}
                    Already have an account? Login!
                  </Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white">
            <div className="max-w-xl px-5 text-center">
              <h2 className="mb-2 text-[42px] font-bold text-zinc-800">
                Check your inbox
              </h2>
              <p className="mb-2 text-lg text-zinc-500">
                We've sent you a verification link to the email address{" "}
                <span className="font-medium text-indigo-500">
                  {formData.email}
                </span>
                .
              </p>
              <Link
                to="/auth/login"
                className="mt-3 inline-block w-96 rounded bg-indigo-600 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-indigo-700"
              >
                Go to login
              </Link>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ForgotPassword;
