import { sagaActionTypes } from "./actions";
import { doLogin, doLogout } from "./authSagas";
import { takeLatest, all } from "redux-saga/effects";
import { getUserProfile } from "./userSaga";
import { getPostCounts } from "./postSaga";
import { getCategory } from "./categorySaga";

function* watchGeneralRequest() {
  yield takeLatest(sagaActionTypes.LOGIN, doLogin);
  yield takeLatest(sagaActionTypes.lOGOUT, doLogout);
  yield takeLatest(sagaActionTypes.GET_USER_PROFILE, getUserProfile);
  yield takeLatest(sagaActionTypes.GET_POST_COUNTS, getPostCounts);
  yield takeLatest(sagaActionTypes.GET_CATEGORY, getCategory);
}

export default function* rootSaga() {
  yield all([watchGeneralRequest()]);
}
