import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";

const SettingsList = () => {
  const navigate = useNavigate();
  const tableHeading = [
    "Title",
    "UniqueName",
    "SettingValue",
    "Status",
    "Action",
  ];
  const [settingList, setSettingList] = useState([]);
  // eslint-disable-next-line
  const [selectedSettingId, setSelectedSettingId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //PAGINATION STATE
  const [searchTxt, setsearchTxt] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(0);
  // setLimit(LIMIT);
  const limit = LIMIT

  const [numberOfTotalData, setNumberOfTotalData] = useState(0);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout;
    if (!!searchTxt) {
      timeout = setTimeout(() => {
        getListOfSettings();
      }, 1500);
    } else {
      getListOfSettings();
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchTxt, currentPage]);

  const getListOfSettings = async () => {
    const { data, success, total, totalPages } = await new APIServices(
      "setting/list"
    ).post({
      page: currentPage,
      limit: limit,
      search: searchTxt,
    });

    if (success) {
      setIsLoading(false);
      setSettingList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };

  const handleDeleteSetting = async () => {
    const { message, success } = await new APIServices(
      `setting/${selectedSettingId}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfSettings();
    }
  };

  const handleEditSetting = (UniqueName) => {
    navigate(`/setting/edit/${UniqueName}`);
  };

  const handleView = (id) => {
    navigate(`/setting/view/${id}`);
  };

  const navigateToAddNewSetting = () => {
    navigate("/setting/add");
  };

  const handleTableSearch = (val) => {
    setsearchTxt(val);
  };

  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {" "}
              <TableEntry
                title="List of Settings"
                navigate={navigateToAddNewSetting}
                handleTableSearch={handleTableSearch}
              />
              <CustomTable
                tableData={settingList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                handleEdit={handleEditSetting}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>
      {showModal && (
        <DeleteModal handleDelete={handleDeleteSetting} close={setShowModal} />
      )}
    </section>
  );
};

export default SettingsList;
