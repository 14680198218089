import React, { useEffect, useState } from "react";
import APIServices from "../../../httpServices/httpServices";
import { LIMIT } from "../../../utilities/constant";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import CustomTable from "../../common/CustomTable";
import TablePagination from "../../common/table/TablePagination";
import { useNavigate, useParams } from "react-router-dom";

const ResponseList = () => {
  const { quesgrpid } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const limit = LIMIT;
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const tableHeading = ["User", "QuestionGroup", "ResponseType", "Action"];
  const [dataList, setDataList] = useState([]);

  const getResponse = async () => {
    const { data, success, totalPages } = await new APIServices(
      `/response/list/${quesgrpid}`
    ).post({ page: currentPage, limit: limit });

    if (success) {
      setDataList(
        data.map((item) => ({
          ...item,
          QuestionGroup: item?.QuestionGroup?.Title,
        }))
      );
      setIsLoading(false);
      setTotalPageNumber(totalPages);
    }
  };

  useEffect(() => {
    getResponse();
    // eslint-disable-next-line
  }, [quesgrpid]);

  const handleView = (id) => {
    navigate(`/user-response/${id}`);
  };
  return (
    <section className="dark:bg-gray-800 ">
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <CustomTable
                tableData={dataList}
                tableHeadingData={tableHeading}
                handleDelete={false}
                handleView={handleView}
                actionField="_id"
              />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
              />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ResponseList;
