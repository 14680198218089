import React, { useEffect, useState } from "react";
import { Field, ErrorMessage } from "formik";
import { TextError } from "../TextError";

const FormikGeoLocationField = ({ label, name, formik }) => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          formik.setFieldValue(name, JSON.stringify({ latitude, longitude }));
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser.");
    }
    // eslint-disable-next-line
  }, [name, navigator]);

  return (
    <div className="mb-4">
      <label className="block mb-2 text-sm font-medium">{label}</label>
      <div className="relative">
        <Field
          type="text"
          name={name}
          className={`block w-full px-4 py-2 text-sm placeholder-gray-500 bg-white border rounded ${
            formik.touched[name] && formik.errors[name] ? "border-red-600" : ""
          }`}
          disabled
          value={location ? `${location.latitude}, ${location.longitude}` : ""}
        />
      </div>
      {error && <p className="text-red-600 text-sm">{error}</p>}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default FormikGeoLocationField;
