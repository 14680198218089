export const sagaActionTypes = {
  LOGIN: "LOGIN",
  lOGOUT: "LOGOUT",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  GET_POST_COUNTS: "GET_POST_COUNTS",
  GET_CATEGORY: "GET_CATEGORY",
};

/****************** USER AUTHENTICATION ********************/
export const LOGIN = (data) => ({
  type: sagaActionTypes.LOGIN,
  payload: data,
});

export const LOGOUT = (data) => ({
  type: sagaActionTypes.lOGOUT,
  payload: data,
});

/*************** USERS *********************/
export const GET_USER_PROFILE = (data) => ({
  type: sagaActionTypes.GET_USER_PROFILE,
  payload: data,
});

/*************** POSTS *********************/
export const GET_POST_COUNTS = (data) => ({
  type: sagaActionTypes.GET_POST_COUNTS,
  payload: data,
});

/*************** CATEGORY *********************/
export const GET_CATEGORY = (data) => ({
  type: sagaActionTypes.GET_CATEGORY,
  payload: data,
});
