import React, { useState, useEffect, useCallback } from "react";
import APIServices from "../../../httpServices/httpServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import DeleteModal from "../../common/modals/DeleteModal";
import CustomImage from "../../common/CustomImage";
import moment from "moment";

const ViewUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (id) {
      getUserDetailView();
    }
    // eslint-disable-next-line
  }, []);

  // Fetch User Data
  const getUserDetailView = useCallback(async () => {
    const { data, success } = await new APIServices(`user/${id}`).get();
    if (success) {
      setUserData(data);
    }
  }, [id]);

  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDeleteUser = async () => {
    const { message, success } = await new APIServices(`user/${id}`).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/users");
    }
  };

  const {
    _id,
    Address,
    AliasName,
    Cellphone,
    DOB,
    Email,
    FullName,
    Gender,
    Phone,
    ProfileImage,
    Remarks,
    Role,
    Status,
    UserName,
    UserType,
  } = userData;

  return (
    <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
      <div className="flex gap-x-4">
        <div className="user-profile w-[180px] h-[180px]">
          <CustomImage
            className="employee-avatar object-cover h-full w-full rounded-md"
            source={!!ProfileImage ? ProfileImage : ""}
          />
          <Link
            to="/users/change-password"
            state={{ userId: _id }}
            className="flex items-center px-4 my-2 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5"
          >
            Change Password
          </Link>
        </div>
        <div className="flex-1">
          <div className="font-bold text-2xl" name="">
            {FullName}
          </div>
          <div className="mb-3 text-gray-500">
            <span className="text-base">{Email}</span>
          </div>
          <div className="grid grid-cols-3 gap-x-2">
            <div className="flex flex-col">
              <span className="block mb-0 text-sm text-gray-600 font-normal">
                {" "}
                Date of Birth{" "}
              </span>
              <span className="font-bold">
                {moment(DOB, moment.ISO_8601).format("LL")}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="block mb-0 text-sm text-gray-600 font-normal">
                Phone{" "}
              </span>
              <span className="mb-5 text-base font-semibold text-gray-800">
                {Phone ? Phone : "--"}{" "}
              </span>
            </div>

            <div className="flex flex-col">
              <span className="block mb-0 text-sm text-gray-600 font-normal">
                Cellphone{" "}
              </span>
              <span className="mb-5 text-base font-semibold text-gray-800">
                {Cellphone ? Cellphone : "--"}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${
                  Status === "Active" ? "bg-green-700" : "bg-red-700"
                }`}
              ></div>
              <span className="text-sm"> {Status} </span>
            </div>

            {Status === "Inactive" && (
              <button
                onClick={() => handleShowDeleteModal(_id)}
                className="flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5"
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="shadow bg-white border-gray-500 p-8 rounded mt-14">
        <h2 className="font-bold text-lg mt-5 mb-2"> Personal Information </h2>
        <div className="flex flex-wrap">
          <DataView label="UserName" value={UserName} />
          <DataView label="Alias Name" value={AliasName} />
          <DataView label="Role" value={Role} />
          <DataView label="UserType" value={UserType} />
          <DataView label="Address" value={Address} />
          <DataView label="Remarks" value={Remarks} />
          <DataView label="Gender" value={Gender} />
        </div>
      </div>

      {showModal && (
        <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />
      )}
    </div>
  );
};

export default ViewUser;
