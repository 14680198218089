import { Form, Formik } from "formik";
import React from "react";
import APIServices from "../../../httpServices/httpServices";
import ChangeProfilePasswordValidation from "../../../validation/ChangeProfilePasswordValidation";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import { t } from "i18next";

const ProfileChangePassword = () => {
  const initialValues = {
    OldPassword: "",
    Password: "",
    ConfirmPassword: "",
  };
  const handleFormikFormSubmit = async (values, actions) => {
    const { setFieldError } = actions;
    const { success, message } = await new APIServices(
      `user/profile/change-password`
    ).post(values);
    if (success) {
      emitSuccessToast("Password changed successfully");
    } else {
      if (message === "Incorrect old password") {
        setFieldError("OldPassword", message);
      } else {
        emitErrorToast(message);
      }
    }
  };

  return (
    <section className="font-poppins">
      <div className="flex items-center justify-center h-screen">
        <div className="p-5 bg-gray-100 rounded-lg lg:rounded-l-none">
          <div className="px-8 mb-4 text-center">
            <h3 className="pt-4 mb-2 text-2xl">{t('Change Your Password')}</h3>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={ChangeProfilePasswordValidation}
            onSubmit={handleFormikFormSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="mb-6">
                  <FormikTextField
                    label="Old Password *"
                    name="OldPassword"
                    type="password"
                    formik={formik}
                  />
                </div>
                <div className="mb-6">
                  <FormikTextField
                    label="New Password *"
                    name="Password"
                    type="password"
                    formik={formik}
                  />
                </div>
                <div className="mb-6">
                  <FormikTextField
                    label="Confirm Password *"
                    name="ConfirmPassword"
                    type="password"
                    formik={formik}
                  />
                </div>
                {/* Submit */}
                <div className="mb-6 text-center">
                  <button
                    className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-full hover:bg-blue-500 focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {t('Reset Password')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default ProfileChangePassword;
