import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import slugify from "slugify";
import APIServices from "../../../httpServices/httpServices";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import QuestionOptionForm from "../../common/QuestionOptionForm";
import SaveButton from "../../common/button/SaveButton";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import * as Yup from 'yup';

const QuestionForm = ({ editData }) => {
  const navigate = useNavigate();
  const { QuestionId, QuestionTitle, OrderList } = useLocation().state;
  const initialValues = {
    Status: true,
    DevnagariTitle: "",
    Title: "",
    Slug: "",
    Detail: "",
    Type: "",
    OrderBy: 0,
    Hint: "",
    IsRequired: false,
    Validation: "Null",
    Point: 0,
    Label: "",
    Tag: "",
    Options: [],
    QuestionGroup: QuestionId,
    ShowTag: false,
    Default: ""
  };


  const [form, setForm] = useState({ ...initialValues });

  let typeOptions = [
    { label: "Single Line Text", value: "Input" },
    { label: "Multi-line Text", value: "LongText" },
    { label: "Choose Only One", value: "Radio" },
    { label: "Choose Multiple Option", value: "Checkbox" },
    { label: "Image", value: "Image" },
    { label: "On/Off Button", value: "Switch" },
    { label: "Select One", value: "Dropdown" },
    { label: "Location", value: "Location" },
  ]
  const validation = ["Null", "Number"];
  let validationOptions = validation.map((item) => ({
    value: item,
    label: item,
  }));

  useEffect(() => {
    if (editData)
      setForm((prev) => ({
        ...prev,
        ...editData,
      }));

    // eslint-disable-next-line
  }, [editData]);

  const handleFormikFormSubmit = async (values, actions) => {
    let { setSubmitting, resetForm } = actions;

    let payload = {
      ...values,
      Status: values.Status ? "Active" : "Inactive",
    };
    const { success, message } = !!!editData
      ? await new APIServices(`question`).post(payload)
      : await new APIServices(`question/${editData._id}`).put(payload);
    if (success) {
      emitSuccessToast(message);
      setSubmitting(false);
      resetForm();
      navigate(`/question-group/view/${QuestionId}`);
    } else {
      emitErrorToast(message);
    }
  };
  const handleNameChange = (value, form) => {
    form.setFieldValue("Title", value);
    form.setFieldValue(
      "Slug",
      slugify(value, { replacement: "_", lower: true })
    );
  };
  const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
    setFieldValue(fieldName, selectedOption.value);
  };

  const validate = Yup.object().shape({
    Status: Yup.boolean(),
    DevnagariTitle: Yup.string().required("Required !"),
    Title: Yup.string().required("Required !"),
    OrderBy: !editData ? Yup.number()
      .required('Required').notOneOf(OrderList, "Order number already exists. please make it unique")
      : Yup.number().required('Required'),
    Type: Yup.string().required("Required !"),
  });

  return (
    <section className="py-16 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-6xl px-4 mx-auto ">
        <Formik
          initialValues={form}
          validationSchema={validate}
          onSubmit={handleFormikFormSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <div className="grid gap-4">
                <div>
                  <FormHeading title={`Add Questions for ${QuestionTitle}`} />
                  <DashCard>
                    <FormikTextField
                      label="Devnagari Title"
                      name="DevnagariTitle"
                      formik={formik}
                    />
                    <FormikOnChangeField
                      label="Title"
                      name="Title"
                      placeholder="Enter a Title"
                      handleChange={handleNameChange}
                      formik={formik}
                    />

                    <FormikTextField
                      label="Slug"
                      name="Slug"
                      formik={formik}
                      disabled
                    />
                    <FormikTextField
                      label="Detail"
                      name="Detail"
                      as="textarea"
                      formik={formik}
                    />
                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                      <FormikSwitchField label="Show Tag" name="ShowTag" />
                      {/* <FormikSwitchField label="ShowAdvanceOption" name="ShowAdvanceOption" /> */}
                    </div>
                    <FormikSelectSingleField
                      label="Type "
                      name="Type"
                      options={typeOptions}
                      handleSelectSingle={handleSelectSingle}
                    />

                    {formik.values.Type === "Radio" ||
                      formik.values.Type === "Dropdown" ||
                      formik.values.Type === "Checkbox" ? (
                      <QuestionOptionForm formik={formik} showTag={formik.values.ShowTag} />
                    ) : null}
                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-3">
                      {formik.values.Type === "Input" ||
                        formik.values.Type === "Long Text" ? (
                        <>
                          <FormikSelectSingleField
                            label="Validation "
                            name="Validation"
                            options={validationOptions}
                            handleSelectSingle={handleSelectSingle}
                          />
                        </>
                      ) : null}

                      <FormikTextField
                        label="OrderBy"
                        name="OrderBy"
                        type="number"
                        formik={formik}
                      />
                      {formik.values.ShowTag && (
                        <FormikTextField
                          label="Point"
                          name="Point"
                          type="number"
                          formik={formik}
                        />
                      )}
                    </div>
                    <FormikTextField
                      label="Label"
                      name="Label"
                      formik={formik}
                    />
                    <FormikTextField
                      label="Default"
                      name="Default"
                      formik={formik}
                    />
                    <FormikTextField
                      label="Hint"
                      name="Hint"
                      as="textarea"
                      formik={formik}
                    />
                    <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                      <FormikSwitchField label="Is Required" name="IsRequired" />
                      <FormikSwitchField label="Status" name="Status" />
                    </div>
                  </DashCard>
                </div>
              </div>
              <SaveButton isSubmitting={formik.isSubmitting} />
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default QuestionForm;
