import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { TextError } from "../../common/TextError";
import APIServices from "../../../httpServices/httpServices";
import {
  emitErrorToast,
  emitSuccessToast,
} from "../../common/toast/ToastContainer";
import MultiTextInsert from "../../common/select/MultiTextInsert";
import SettingValidationSchema from "../../../validation/SettingValidationSchema";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import FormikImageField from "../../common/form/FormikImageField";
import FormikTextAreaField from "../../common/form/FormikTextAreaField";
import FormikTextField from "../../common/form/FormikTextField";
const SettingsForm = ({ data, id }) => {
  const navigate = useNavigate();
  const initial = {
    Title: "",
    UniqueName: "",
    SettingValue: [],
    Status: false,
    Image: "",
    Detail: "",
    Url: ""
  };
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (data) {
      setInitialValues(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleFormikFormSubmit = async (values, actions) => {
    let { setSubmitting, resetForm } = actions;
    let payload = {
      ...values,
      Status: values.Status ? "Active" : "Inactive",
    };
    const { SettingValue, ...rest } = payload
    let formData = new FormData();
    formData.append("SettingValue", JSON.stringify(SettingValue));
    Object.keys(rest).forEach((key, index) => {
      formData.append(key, rest[key]);
    });
    const { success, data, message } = !!!id
      ? await new APIServices(`setting`).post(formData)
      : await new APIServices(`setting/${id}`).put(formData);
    if (success) {
      setSubmitting(false);
      resetForm();
      emitSuccessToast(message);
      navigate(`/setting/view/${data._id}`, { replace: true });
    } else {
      emitErrorToast(message);
    }
  };

  return (
    <section className="py-16 bg-gray-100 dark:bg-gray-800">
      <div className="max-w-4xl px-4 mx-auto ">
        <div className="p-6 bg-white rounded-md shadow dark:border-gray-800 dark:bg-gray-900">
          <h2 className="mb-6 text-xl font-medium leading-6 text-gray-900 dark:text-gray-300">
            Settings's Information
          </h2>
          <Formik
            initialValues={initialValues || initial}
            validationSchema={SettingValidationSchema}
            onSubmit={handleFormikFormSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="mb-6">
                  <label
                    htmlFor="toggleB"
                    className="block items-center cursor-pointer"
                  >
                    <div className="mb-2">Status</div>
                    <div className="relative flex">
                      <Field
                        type="checkbox"
                        id="toggleB"
                        name="Status"
                        className="sr-only"
                      />
                      <div className="block w-14 h-8 bg-gray-200 rounded-full"></div>
                      <div className="absolute w-6 h-6 transition bg-gray-700 rounded-full dot left-1 top-1"></div>
                    </div>
                  </label>
                </div>

                {/* Title and UniqueName*/}
                <div className="grid w-full gap-4 mb-6 lg:grid-cols-2">
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium dark:text-gray-400"
                      htmlFor=""
                    >
                      {t('Title *')}
                    </label>
                    <Field
                      type="text"
                      name="Title"
                      placeholder="Enter a Title"
                      className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.Title && formik.errors.Title
                        ? "border-red-600"
                        : null
                        }`}
                    />
                    <ErrorMessage name="Title" component={TextError} />
                  </div>
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium dark:text-gray-400"
                      htmlFor=""
                    >
                      UniqueName *
                    </label>
                    <Field
                      name="UniqueName"
                      type="text"
                      placeholder="Enter a unique name"
                      className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.UniqueName && formik.errors.UniqueName
                        ? "border-red-600"
                        : null
                        }`}
                    />
                    <ErrorMessage name="UniqueName" component={TextError} />
                  </div>
                </div>

                <FormikImageField
                  label="Image"
                  name="Image"
                  formik={formik}
                />
                <div>
                  <label
                    className="block mb-2 text-sm font-medium dark:text-gray-400"
                    htmlFor=""
                  >
                    Setting Value *
                  </label>
                  <Field
                    type="hidden"
                    name="SettingValue"
                    placeholder="Enter a Title"
                    className={`block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded dark:text-gray-400 dark:placeholder-gray-500 dark:border-gray-800 dark:bg-gray-800 ${formik.touched.SettingValue && formik.errors.SettingValue
                      ? "border-red-600"
                      : null
                      }`}
                  />
                  <MultiTextInsert
                    data={formik.values.SettingValue}
                    setFieldValue={formik.setFieldValue}
                    fieldName="SettingValue"
                  />
                  <ErrorMessage name="SettingValue" component={TextError} />
                </div>
                {/* Details */}
                <FormikTextAreaField
                  label="Detail"
                  name="Detail"
                  formik={formik}
                />
                <FormikTextField label="Url" name="Url" formik={formik} />



                {/* Submit */}

                <div className="mt-7">
                  <div className="flex justify-start space-x-2">
                    <button
                      type="submit"
                      className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default SettingsForm;
