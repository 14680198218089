import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import SettingsForm from "../../container/settings/SettingsForm";

const EditSetting = () => {
  const { id } = useParams();
  const [settingDataToEdit, setSettingDataToEdit] = useState({
    Title: "",
    UniqueName: "",
    SettingValue: [],
    Status: false,
  });
  useEffect(() => {
    if (id) getSettingDetail();
    // eslint-disable-next-line
  }, [id]);

  const getSettingDetail = async () => {
    const { data, success } = await new APIServices(`setting/${id}`).get();

    if (success) {
      setSettingDataToEdit({
        ...data,
        Status: data.Status === "Active" ? true : false,
        SettingValue: data.SettingValue ? JSON.parse(data.SettingValue) : [],
      });
    }
  };

  //checking from uniquename in api
  return (
    <div>
      <SettingsForm data={settingDataToEdit} id={id} />
    </div>
  );
};

export default EditSetting;
