import { call, put } from "redux-saga/effects";
import { post } from "../../httpServices";
import { setCategory } from "../features/category/categorySlice";

export function* getCategory({ type, payload }) {
  try {
    const { data } = yield call(post, payload);
    let domesticProduct = [];
    let crops = [];

    data?.data?.forEach((cat) => {
      switch (cat.EffectiveModule) {
        case "domestic-product":
          domesticProduct.push({ Slug: cat.Slug, id: cat._id });
          break;

        case "crop":
          crops.push({ Slug: cat.Slug, id: cat._id });
          break;

        default:
          break;
      }
    });

    yield put(setCategory({ domesticProduct: domesticProduct, crop: crops }));
  } catch (error) {
    yield put(setCategory({ data: null }));
  }
}
