import React from 'react'

function DashCard({ children }) {
    return (
        <div className="p-4 mb-8 bg-white rounded-md shadow lg:p-8 dark:border-gray-800 dark:bg-gray-900">
            {children}
        </div>
    )
}

export default DashCard