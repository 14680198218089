import { get, post, put, del, patch } from ".";

class APIServices {
  url;
  data = [];
  message = "";
  success = false;
  total = 0;
  totalPages = 0;
  statusCode = "";

  constructor(url) {
    this.url = url;
  }
  getURL(id) {
    return !!id ? `/${this.url}/${id}` : `/${this.url}`;
  }

  async get() {
    await get({ url: this.url })
      .then((response) => {
        if (response.data?.result) {
          this.data = response.data?.data;
          this.success = true;
          this.message = response.data?.message;
          this.statusCode = response.status;
        } else {
          this.message = response.data?.message;
          this.statusCode = response.status;
        }
      })
      .catch((error) => {
        this.message = error.response?.data?.message;
        this.statusCode = error.response?.status;
      });
    return {
      data: this.data,
      success: this.success,
      message: this.message,
      statusCode: this.statusCode,
    };
  }

  async post(formData) {
    await post({ url: this.url, data: formData })
      .then((response) => {
        if (response.data?.result) {
          let { total, totalPages } = response?.data;
          if (!!total && !!totalPages) {
            this.total = total;
            this.totalPages = totalPages;
          }
          this.data = response.data?.data;
          this.success = true;
          this.message = response.data?.message;
          this.statusCode = response.status;
        } else {
          this.message = response?.data?.message;
          this.statusCode = response.status;
        }
      })
      .catch((error) => {
        this.message = error.response?.data?.message;
        this.statusCode = error.response?.status;
      });
    if (!!this.total && !!this.totalPages) {
      return {
        data: this.data,
        success: this.success,
        message: this.message,
        total: this.total,
        totalPages: this.totalPages,
        statusCode: this.statusCode,
      };
    } else {
      return {
        data: this.data,
        success: this.success,
        message: this.message,
        statusCode: this.statusCode,
        total: 0,
        totalPages: 0,
      };
    }
  }

  async put(formData) {
    await put({ url: this.url, data: formData })
      .then((response) => {
        if (response.data?.result) {
          this.data = response.data?.data;
          this.success = true;
          this.message = response.data?.message;
          this.statusCode = response.status;
        } else {
          this.message = response.data?.message;
          this.statusCode = response.status;
        }
      })
      .catch((error) => {
        this.message = error.response?.data?.message;
        this.statusCode = error.response?.status;
      });
    return {
      data: this.data,
      success: this.success,
      message: this.message,
      statusCode: this.statusCode,
    };
  }

  async patch(id, formData) {
    await patch({ url: this.getURL(id), data: formData })
      .then((response) => {
        if (response.data?.result) {
          this.success = true;
          this.data = response.data?.data;
          this.message = response.data?.message;
          this.statusCode = response.status;
        } else {
          this.message = response.data?.message;
          this.statusCode = response.status;
        }
      })
      .catch((error) => {
        this.message = error.response?.data?.message;
        this.statusCode = error.response?.status;
      });
    return {
      data: this.data,
      success: this.success,
      message: this.message,
      statusCode: this.statusCode,
    };
  }

  async delete(id) {
    await del(this.getURL(id))
      .then((response) => {
        if (response.data?.result) {
          this.success = true;
          this.data = response.data?.data;
          this.message = response.data?.message;
          this.statusCode = response.status;
        } else {
          this.message = response.data?.message;
          this.statusCode = response.status;
        }
      })
      .catch((error) => {
        this.message = error.response?.data?.message;
        this.statusCode = error.response?.status;
      });
    return {
      data: this.data,
      success: this.success,
      message: this.message,
      statusCode: this.statusCode,
    };
  }
}

export default APIServices;
