import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import CustomImage from "../../common/CustomImage";

const OwnerAnswerView = () => {
  const { id } = useParams();
  const [itemDetails, setItemDetails] = useState([]);

  useEffect(() => {
    if (id) {
      getItemDetail();
    }
    // eslint-disable-next-line
  }, []);

  const arrayStringToString = (inputString) => {
    const array = JSON.parse(inputString);
    let result = "";
    if (array) {
      result = array?.join(", ");
    }
    return result;
  };

  // Fetch User Data
  const getItemDetail = async () => {
    const { data, success } = await new APIServices(`response/${id}`).get();
    if (success) {
      setItemDetails(data?.Responses);
    }
  };
  const answerFunc = (itemdetail) => {
    // const { Type } = itemdetail?.Question;
    const { Type, Answer } = itemdetail;
    switch (Type) {
      case "Image":
        return (
          <div className="w-[180px] h-[180px] mt-4">
            <CustomImage source={Answer} className="h-full object-cover" />
          </div>
        );
      case "Checkbox":
        return <p>{arrayStringToString(Answer)}</p>;

      case "Location":
        const geoLocationObject = Answer ? JSON.parse(Answer) : "";
        return <div>
          <p>
            Latitude: <span>{geoLocationObject?.latitude}</span>
          </p>
          <p>
            Longitude: <span>{geoLocationObject?.longitude}</span>
          </p>
          {
            geoLocationObject.altitude && (
              <p>
                Altitude: <span>{geoLocationObject?.altitude}</span>
              </p>
            )
          }
        </div>

      default:
        return <p>{Answer}</p>;
    }
  };

  return (
    <section className="pt-8 pb-16 bg-gray-100 dark:bg-gray-800 ">
      <div className="max-w-5xl mx-auto">
        <h1 className="mb-4 text-3xl font-bold"> Your Answers </h1>
        <div className="mb-4">
          User : <span className="font-bold">{itemDetails[0]?.User}</span>
        </div>

        <div className="pt-6 bg-white rounded shadow ">
          {itemDetails?.length > 0 &&
            itemDetails.map((itemdetail, index) => (
              <div
                key={index}
                className="mb-4  question-answer-block border-b px-6 border-gray-300 pb-4"
              >
                <div className="font-semibold question">
                  {itemdetail?.DevnagariTitle}
                </div>
                <div className="answer">{answerFunc(itemdetail)}</div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default OwnerAnswerView;
