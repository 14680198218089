import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import ToastContainer from '../common/toast/ToastContainer'
import Footer from './Footer'
import HeaderNav from './HeaderNav'
import UserSideNav from './UserSideNav'


const UsersLayout = () => {
    const [openSideNav, setOpenSideNav] = useState(true)
    return (
        <div className="bg-gray-100 dark:bg-gray-800">
            <div className="body-content">

                {/* Side nav */}
                <UserSideNav openSideNav={openSideNav}></UserSideNav>

                {/* Hero section */}
                <div id="dash"
                    className={`${!openSideNav ? 'lg:ml-0' : 'lg:ml-[280px]'} mx-auto transition-all content-wrapper`}>
                    <section
                        className="sticky top-0 z-40 px-2 py-2.5 bg-white shadow dark:text-gray-100 dark:bg-gray-900 lg:px-2">

                        {/* HeaderNav */}
                        <HeaderNav openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
                    </section>
                    <Outlet />
                </div>
                {/* Footer */}
                <Footer />
            </div>
            <ToastContainer />
        </div >
    )
}

export default UsersLayout