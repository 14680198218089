import { t } from 'i18next'
import React from 'react'

function FormHeading({ icon, title}) {
    return (
        <div className="flex items-center gap-2 mb-3">
            
            {icon}
            <h3 className="text-base font-medium leading-6 text-gray-900 dark:text-gray-300">
            {t(title)}
            </h3>
        </div>
    )
}

export default FormHeading