import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import QuestionList from "../question/QuestionList";

const ViewQuestionGroup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      getDetailView();
    }
    // eslint-disable-next-line
  }, [id]);

  // Fetch User Data
  const getDetailView = async () => {
    const { data, success } = await new APIServices(
      `question-group/${id}`
    ).get();
    if (success) {
      setData(data);
    }
  };

  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDeleteRole = async () => {
    const { message, success } = await new APIServices(
      `question-group/${id}`
    ).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/question-group");
    }
  };

  const {
    _id,
    Title,
    Detail,
    Status,
    DisplayNumber,
  } = data;

  return (
    <>
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="flex justify-between mt-5 mb-4 items-end">
          <h2 className="font-bold text-lg"> Question Group Information </h2>
        </div>
        <div className="flex items-center justify-between">
          <DataView label="Title" value={Title} />
          <div className="flex items-center">
            <div
              className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${Status === "Active" ? "bg-green-700" : "bg-red-700"
                }`}
            ></div>
            <span className="text-sm"> {Status} </span>
          </div>
          {Status === "Inactive" && (
            <button
              onClick={() => handleShowDeleteModal(_id)}
              className=" flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600"
            >
              Delete
            </button>
          )}
        </div>

        <div className="shadow bg-white border-gray-500 p-8 rounded">
          <div className="flex flex-wrap">
            <DataView label="Detail" value={Detail} />
            {/* <DataView
              label="ActivateFrom"
              value={moment(ActivateFrom).format("YYYY-MM-DD")}
            />
            <DataView
              label="ActivateTo"
              value={moment(ActivateTo).format("YYYY-MM-DD")}
            /> */}
            {/* <DataView label="TotalActiveSurveyForm" value={TotalActiveSurveyForm} /> */}
          </div>
        </div>

        {showModal && (
          <DeleteModal handleDelete={handleDeleteRole} close={setShowModal} />
        )}

        <div className="flex justify-between mt-4">
          <button
            onClick={() =>
              navigate(`/response`, {
                state: {
                  QuestionGroup: _id,
                  DisplayNumber: DisplayNumber,
                },
              })
            }
            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
          >
            Start a survey
          </button>

          <Link
            to={`/response-list/${_id}`}
            className="flex items-center px-4 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 mb-5"
          >
            Response List
          </Link>
        </div>
      </div>
      <QuestionList Id={_id} Title={Title} />
    </>
  );
};

export default ViewQuestionGroup;
