import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RolesForm from "../../container/roles/RolesForm";
import APIServices from "../../../httpServices/httpServices";

const EditRole = () => {
  const { id } = useParams();
  const [roleDataToEdit, setRoleDataToEdit] = useState();
  useEffect(() => {
    if (id) getRoleDetail();
    // eslint-disable-next-line
  }, [id]);

  const getRoleDetail = async () => {
    const { data, success } = await new APIServices(`role/${id}`).get();

    if (success) {
      setRoleDataToEdit({
        ...data,
        Status: data.Status === "Active" ? true : false,
        PermissionValue: data.PermissionValue
          ? JSON.parse(data.PermissionValue)
          : [],
      });
    }
  };

  return (
    <div>
      <RolesForm formData={roleDataToEdit} id={id} />
    </div>
  );
};

export default EditRole;
