import React from "react";

function SaveButton({ type = "submit", isSubmitting, label = "Save" }) {
  return (
    <button
      type={type}
      disabled={isSubmitting}
      className={`inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white ${
        isSubmitting
          ? "cursor-not-allowed bg-primary opacity-70"
          : "hover:opacity-90 bg-primary"
      } transition ease-in-out duration-150 `}
    >
      {isSubmitting ? (
        <>
          <svg
            className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Saving ...
        </>
      ) : (
        label
      )}
    </button>
  );
}

export default SaveButton;
