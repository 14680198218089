import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIServices from "../../../httpServices/httpServices";
import DashCard from "../../common/DashCard";
import FormHeading from "../../common/FormHeading";
import SaveButton from "../../common/button/SaveButton";
import FormikOnChangeField from "../../common/form/FormikOnChangeField";
import FormikSwitchField from "../../common/form/FormikSwitchField";
import FormikTextField from "../../common/form/FormikTextField";
import { emitErrorToast, emitSuccessToast } from "../../common/toast/ToastContainer";
import slugify from "slugify";
import FormikSelectSingleField from "../../common/form/FormikSelectSingleField";
import QuestionGroupValidationSchema from "../../../validation/QuestionGroupValidationSchema";

const QuestionGroupForm = ({ editData }) => {
    const navigate = useNavigate();
    const initialValues = {
        Status: true,
        Title: "",
        Slug: "",
        Detail: "",
        ActivateFrom: "",
        ActivateTo: "",
        TotalActiveSurveyForm: 0,
        DisplayNumber: 0,
    };

    const [form, setForm] = useState({ ...initialValues });

    const displayNumber = ["1", "2", "3", "4", "5", "All"];

    let displayNumberOptions = displayNumber.map((item) => ({
        value: item,
        label: item,
    }));

    useEffect(() => {
        if (editData)
            setForm((prev) => ({
                ...prev,
                ...editData,
            }));
        // eslint-disable-next-line
    }, [editData]);

    const handleFormikFormSubmit = async (values, actions) => {
        let { setSubmitting, resetForm } = actions;

        let payload = {
            ...values,
            Status: values.Status ? "Active" : "Inactive",
        };

        const { success, message } = !!!editData
            ? await new APIServices(`question-group`).post(payload)
            : await new APIServices(`question-group/${editData._id}`).put(payload);
        if (success) {
            emitSuccessToast(message);
            setSubmitting(false);
            resetForm();
            navigate(`/question-group`);
        } else {
            emitErrorToast(message);
        }
    };
    const handleNameChange = (value, form) => {
        form.setFieldValue("Title", value);
        form.setFieldValue("Slug", slugify(value, { lower: true }));
    };
    const handleSelectSingle = (selectedOption, fieldName, setFieldValue) => {
        setFieldValue(fieldName, selectedOption.value);
    };
    return (
        <section className="py-16 bg-gray-100 dark:bg-gray-800">
            <div className="max-w-6xl px-4 mx-auto ">
                <Formik
                    initialValues={form}
                    validationSchema={QuestionGroupValidationSchema}
                    onSubmit={handleFormikFormSubmit}
                    enableReinitialize
                >
                    {(formik) => (
                        <Form>
                            <div className="grid gap-4">
                                <div>
                                    <FormHeading title={"Question Group Information"} />
                                    <DashCard>
                                        <FormikOnChangeField
                                            label="Title"
                                            name="Title"
                                            placeholder="Enter a Title"
                                            handleChange={handleNameChange}
                                            formik={formik}
                                        />

                                        <FormikTextField
                                            label="Slug"
                                            name="Slug"
                                            formik={formik}
                                            disabled
                                        />
                                        {/* <div className="grid w-full gap-6 mb-6 lg:grid-cols-2">
                                            <FormikTextField
                                                label="Activate From"
                                                name="ActivateFrom"
                                                type="date"
                                                formik={formik}
                                            />
                                            <FormikTextField
                                                label="Activate To"
                                                name="ActivateTo"
                                                type="date"
                                                formik={formik}
                                            />
                                        </div> */}
                                        {/* <FormikTextField
                                            label="Total Active Survey Form"
                                            name="TotalActiveSurveyForm"
                                            type="number"
                                            formik={formik}
                                        /> */}
                                        <FormikSelectSingleField
                                            label="How many questions you want to display at a time?"
                                            name="DisplayNumber"
                                            options={displayNumberOptions}
                                            handleSelectSingle={handleSelectSingle}
                                        />
                                        <FormikTextField
                                            label="Detail"
                                            name="Detail"
                                            as="textarea"
                                            placeholder="Please write some detail"
                                            formik={formik}
                                        />

                                        <FormikSwitchField label="Status" name="Status" />
                                    </DashCard>
                                </div>
                            </div>
                            <SaveButton isSubmitting={formik.isSubmitting} />
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
};

export default QuestionGroupForm;
