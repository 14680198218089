import * as yup from "yup";
const regExeNum = /^9\d{9}$/;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

let UserValidationSchema = yup.object().shape({
  Status: yup.boolean(),
  FullName: yup
    .string()
    .max(20, "Must be 20 characters or less")
    .required("Required !"),
  Email: yup.string().email("Invalid email address"),
  ProfileImage: yup
    .mixed()
    .notRequired()
    .nullable()
    .test("fileSize", "The file is too large", (value) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024 * 2;
    })
    .test(
      "fileFormat",
      "Unsupported File Format",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  UserPass: yup.string().required("Required !"),
  UserType: yup.string().required("Required !"),
  Role: yup.string().required("Required !"),
  AliasName: yup.string().max(20, "Must be 20 characters or less"),
  Gender: yup
    .string()
    .oneOf(["Male", "Female"], "Invalid  Gender")
    .required("Required !"),
  Cellphone: yup.string().matches(regExeNum, "Enter valid 10 digit number"),
  Phone: yup
    .string()
    .required("Required !")
    .matches(regExeNum, "Enter valid 10 digit number"),
  LocalBodies: yup.string(),
  Address: yup.string(),

  ReferralUser: yup.string(),
  Remarks: yup.string(),
});

export default UserValidationSchema;
