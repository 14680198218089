import React from "react";

const TableSkeleton = () => {
  return (
    <>
      <div className="flex flex-wrap items-center justify-between px-6 pb-4 border-b">
        <div className="w-[180px] bg-gray-200 animate-pulse rounded h-4 ">
          {" "}
        </div>
        <div className="w-16 h-6 rounded opacity-50 bg-primary animate-pulse "></div>
      </div>
      <div className="pt-4 overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="text-sm text-left text-gray-500 ">
              <th className="pb-3 ">
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </th>
              <th className="pb-3 ">
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </th>
              <th className="pb-3 ">
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </th>
              <th className="pb-3 ">
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </th>
              <th className="pb-3 ">
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-sm border-t dark:bg-gray-800">
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
            </tr>
            <tr className="text-sm border-t dark:bg-gray-800">
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
            </tr>
            <tr className="text-sm border-t dark:bg-gray-800">
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
              <td>
                <div className="w-24 h-5 mx-6 my-4 bg-gray-200 rounded animate-pulse"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-between p-6 border-t dark:border-gray-700">
        <div className="w-[180px] h-5 bg-gray-200 animate-pulse rounded"></div>
        <div className="flex gap-4">
          <div className="w-6 h-6 rounded opacity-50 bg-primary animate-pulse"></div>
          <div className="w-6 h-6 rounded opacity-50 bg-primary animate-pulse"></div>
          <div className="w-6 h-6 rounded opacity-50 bg-primary animate-pulse"></div>
          <div className="w-6 h-6 rounded opacity-50 bg-primary animate-pulse"></div>
        </div>
      </div>
    </>
  );
};

export default TableSkeleton;
